/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { MortgageGuideRoutes } from "GenericMortgageGuides";
import Footer from './components/FooterNew/index';
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const location = (window.location.pathname).toLowerCase();

const baseName = process.env.REACT_APP_BASENAME;

let basePath = baseName;
if (baseName === "/studentcashoutrefinance") {
  basePath = basePath + "/";
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    {location === baseName || location === "/studentcashoutrefinance/" ? <App /> : ""}
    {location !== baseName && location !== "/studentcashoutrefinance/" ?
      <MortgageGuideRoutes  homePath={basePath} showHeader={true} showFooter={true} FooterComponent={Footer} /> : ""}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
