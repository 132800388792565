/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/
import React, { useEffect, useState, lazy, Suspense } from "react";
import { format, parseISO } from "date-fns";
import axios from "axios";
import "@fontsource/poppins";
import { PressReleaseComponent } from "press-release";
import "./App.css";
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";
const ProductsHeader = lazy(() => import("./components/Menubar/ProductsHeader.js"));
const Header = lazy(() => import("./components/Header"));
const Highlights = lazy(() => import("./components/Highlights"));
const BannerCarousel = lazy(() => import("./components/BannerCarousel"));
const Footer = lazy(() => import("./components/FooterNew/index.js"));
const Navbar = lazy(() => import("../src/components/HeaderNav"));
const LogoSection = lazy(() => import("./components/Logo Section/Logo"));
function App() {
  const imgCDNURL = process.env.REACT_APP_STUDENTLOAN_CDN_URL;
  const [themeId, setThemeId] = useState("fnma-student-pricing");
  const [configJSON, setConfigJSON] = useState("");
  const [CONV15, setCONV15] = useState("");
  const [CONV30, setCONV30] = useState("");
  const [pricingAsOfDate, setPricingAsOfDate] = useState("");

  async function locationError(error) {
    // Get the default price scenario
    await getPrice();
  }
 
  /**
   * @description Get the pricing config file details
   * 
   */
   const getConfigData = async () => {
    try {
      
      const configData = await axios(
        `${process.env.REACT_APP_RATE_PRODUCT}/fnma-student-config.json`
      );
 
      //console.log(JSON.stringify(configData.data[0]));
      if (configData.data[1].CONV_30_YR_FIXED) {
        setConfigJSON(configData.data[1].CONV_30_YR_FIXED);
      }
    } catch (error) {
      console.error("Unable to load fnma-student-config.json file from S3 bucket: ", error);
    }
  };
 
  /**
   * @description Get the static pricing data from s3.
   * 
   */
   const getPrice = async (userStateCode) => {
    try {
      // Get the static data from s3 for the filtered state else return the default data
      // Get the state list
      const stateList = await axios(
        `${process.env.REACT_APP_RATE_PRODUCT}/state.json`
      );
      // Filter the state which has the county and zipcode
      const propertyStateData = stateList.data.find(
        (d) => d.state.code === userStateCode
      );
     
      let response;
      if (propertyStateData) {
        const county = propertyStateData.state.county.code;
        const zipcode = propertyStateData.state.county.zipcode;
        response = await axios(
          `${
            process.env.REACT_APP_RATE_PRODUCT
          }/${themeId}/${userStateCode.toLowerCase()}/${county}/${zipcode}/products.json`
        ).catch((error) => {
          console.error("Unable to pull pricing for user state: ", error);
          locationError();
        });
      } else {
        response = await axios(
          `${process.env.REACT_APP_RATE_PRODUCT}/${themeId}/default.json`
        );
      }
      
      const parseValue = parseISO(response.data.createdAt);
      const dateTime = format(parseValue, "MM/dd/yyyy hh:mm a");
      setPricingAsOfDate(dateTime);
 
      if (response.data.CONV_15_YR_FIXED) {
        setCONV15(response.data.CONV_15_YR_FIXED);
      }
      
      if (response.data.CONV_30_YR_FIXED) {
        setCONV30(response.data.CONV_30_YR_FIXED);
      }
    } catch (error) {
      console.error("Unable to load todays rate: ", error);
    }
  };
 
  useEffect(() => {
    let userIP = "";
    let userZipcode = "";
    let userState = "";
 
    const fetchData = async () => {
      await axios
        .get("https://api.ipify.org", { timeout: 5000 })
        .then((response) => {
          userIP = response.data;
          getConfigData();
          
          if (userIP) {
            axios
            .post(
              process.env.REACT_APP_MAXMIND,
              { ip: userIP },
              {
                headers: { "Content-Type": "application/json" },
              },
              { timeout: 5000 }
            )
            .then((response) => {
              
              if (
                response.data !== "IP Not Available" &&
                response.data !== "Invalid Country"
              ) {
                userZipcode = response.data.zipcode;
                
                if (userZipcode) {
                  axios
                    .get(
                      process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + userZipcode,
                      {
                        headers: { "Content-Type": "application/json" },
                      },
                      { timeout: 5000 }
                    )
                    .then((response) => {
                      userState = response.data.state;
                     
                      // get the price scenario
                      getPrice(userState);
                    })
                    .catch((error) => {
                      console.error("Get ZIPCODE Error: ", error);
                      locationError();
                    });
                } else {
                  locationError();
                }
              } else {
                locationError();
              }
            })
            .catch((error) => {
              console.error("Post MAXMIND Error: ", error);
              locationError(error);
            });
          }
        })
        .catch((error) => {
          console.error("Get ipify Error: ", error);
          locationError(error);
        });
    };
 
    fetchData();
  }, []);
 
  const appVersion = appversion.version;
  return (
    <>
     <CacheBuster
          currentVersion={appVersion}
          isEnabled={true}
          isVerboseMode={false}
          metaFileDirectory={"."}
        >
      <div className="bodyContainer mb-5">
        <img
          src={imgCDNURL + "6dots-group.png"}
          className="dots-group img-fluid"
          alt="6dots-group"
        />
        <img
          src={imgCDNURL + "student-loan-cash-out-refi-desktop.webp"}
          className="desktop-bg img-fluid"
          alt="desktop-bg"
        />
        <img
          src={imgCDNURL + "student-loan-cash-out-refi-mobile.webp"}
          className="mobile-bg img-fluid"
          alt="mobile-bg"
        />
        <Suspense fallback={<div />}>
          <Navbar />
        </Suspense> 
        <Suspense fallback={<div />}>
        <Header configJSON={configJSON} CONV15={CONV15} CONV30={CONV30} pricingAsOfDate={pricingAsOfDate}/>
        </Suspense>

        <Suspense fallback={<div />}>
      <LogoSection fetchpriority="low" />
      </Suspense>
      </div>
      <Suspense fallback={<div />}>
        <Highlights />
      </Suspense>
      <Suspense fallback={<div />}>
      <div className="news pt-4">
          <PressReleaseComponent />
        </div>
      </Suspense>
      <Suspense fallback={<div />}>
        <BannerCarousel title="Video Testimonials" />
      </Suspense>
      <Suspense fallback={<div />}>
        <Footer />
      </Suspense>
      </CacheBuster>
    </>
  );
}

export default App;
